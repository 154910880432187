
import { defineComponent, reactive } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import { timeFormats } from '@/definitions/_general/_data/utc'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmFormLine,
  },
  props: {
    timeFormatActive: {
      type: String,
    },
  },
  setup(props) {
    const localization = reactive({
      timeFormat: props.timeFormatActive,
    })

    return {
      timeFormats,
      localization,
    }
  },
})
